// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Copyright',
  slug: '/copyright',
  abstract: 'Copyright',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Copyright', slug: '/copyright' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     launch: file(relativePath: { eq: "launch.jpg" }) {
//       ...max900
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
    <div className="container">
      <h1>Copyright Notice</h1>
      <p>
        <i>
          “At Auroville nothing belongs to anyone in particular. All is
          collective property. To be utilised with my blessings for the welfare
          of all.”
        </i>
        <br />- Mother, 14.5.70
      </p>
      <ul>
        <li>Title: Poetic and...</li>
        <li>Copyright: 2021 Our Home</li>
        <li>Publisher: Our Home, under Auroville Art Service</li>
        <li>First edition: 2021</li>
        <li>Author: Anandi Zhang</li>
        <li>
          Lisa Suchanek contributed images of paintings for front cover, Poetic
          and Pensive.
        </li>
        <li>Web Book handcrafted by Prisma Design</li>
      </ul>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
